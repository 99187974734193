.backUp {
    position: fixed;
    right: 20px;
    bottom: 10px;
    padding: 10px;
    border-radius: 50%;
    line-height: 0;
    background-color: var(--white);
    transition: all 0.3s ease;
    z-index: 2;

    path {
        transition: all 0.3s ease;
    }

    &:hover {
        background-color: var(--grey);

        path {
            fill: var(--white);
        }
    }
}