.slide-container {
    width: 600px;
    max-width: 100vw;
    height: 300px;
}

.slides {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 52%;

    .controls {
        display: flex;
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;

        button {
            padding: 2px 25px;
            font-size: 150%;
            
            &:nth-of-type(2) {
                margin-left: auto;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.6);
            }
        }
    }

    .images {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .slide {
        height: 0;
        padding-top: 60%;
        width: 100%;
        left: 0;
        position: absolute;
        transition: left 0.35s ease;
        z-index: 0;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        &.active {
            left: 0;
            z-index: 1;
        }

        &.left {
            left: -100%;
            z-index: 2;
        }
        
        &.right {
            left: 100%;
            z-index: 2;
        }
    }
}