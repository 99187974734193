.post {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 18px;
    position: relative;
    background: var(--white);

    &::before {
        content: " ";
        position: absolute;
        top: -1px;
        left: -1px;
        background: var(--grey);
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        z-index: -1;
        transition: all 0.35s ease;
    }

    .details {
        padding: 18px;

        h3 span,
        .read-more {
            font-weight: 400;
            font-size: 80%;
        }

        .summary {
            margin-bottom: 8px;
        }

        .read-more {
            font-weight: 400;
        }
    }

    a {
        text-decoration: none;
    }

    .tech-list {
        display: flex;
        flex-flow: wrap;
        margin-bottom: 8px;
        font-size: 80%;

        span {
            white-space: nowrap;
        }
    }

    &:hover {
        &::before {
            width: calc(100% + 10px);
            top: -5px;
            left: -5px;
            height: calc(100% + 10px);
        }

        h3,
        .read-more {
            text-decoration: underline;
        }
    }
}

@media screen and (max-width:768px) {
    .post {
        display: block;
    }
}