form {
    display: grid;
    gap: 15px;
    margin-bottom: 20px;

    button {
        margin-top: 10px;
        margin-left: auto;

        &.good {
            background: green !important;
            color: var(--white) !important;
            opacity: 1;
        }
    }

    input,
    textarea {
        display: block;
        width: 100%;
        padding: 8px 8px 8px 13px;
        border: 2px solid var(--white);
        border-left-width: 0;
        font-size: 110%;
        transition: all 0.3s ease;
        background: #efefef;

        &.error {
            padding: 8px 8px 8px 8px;
            border: 2px solid red;
            border-left-width: 5px;
            background-color: rgb(255, 214, 214);
        }
    }

    textarea {
        resize: none;
    }
}