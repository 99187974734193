body.dark {
    background: var(--grey);
    color: var(--white);

    a {
        color: var(--white);

        &:hover {
            color: var(--highlight);
        }

        &.active {
            color: var(--highlight)
        }
    }

    header,
    header nav {
        background: var(--grey);
    }

    .post {
        background: var(--grey);

        &::before {
            background-color: var(--white);
        }
    }

    .mobile-menu path,
    p.icons svg path {
        fill: var(--white);
    }

    .icons a:hover path,
    .icons button:hover path {
        fill: var(--highlight);
    }

    form button:hover,
    a.button:hover {
        background-color: var(--white);
        color: var(--grey);
    }

    .backUp {
        path {
            fill: var(--grey);
        }

        &:hover path {
            fill: var(--white);
        }
    }

    .skills {
        h3 {
            background: var(--white);
            color: var(--grey);
        }
    }
}