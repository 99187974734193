.skills {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 5px;

    &>div {
        border: 1px solid;
    }

    h3 {
        padding: 5px;
        background: var(--grey);
        color: var(--white);
    }

    ul {
        padding: 5px 10px 10px ;
        list-style: none;
    }
}

@media screen and (max-width: 768px) {
    .skills {
        grid-template-columns: 1fr;
    }
}